import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import SpinnerSVG from "../SVGs/SpinnerSvg";

const Wrapper = styled.div`
    text-align: center;
    position: relative;
    margin-top: 52px;
`;

const Button = styled.button`
    ${typography.label};
    line-height: 1.3;
    font-weight: 600;
    text-decoration: none;
    color: ${color("dark")};
    border: 3px solid ${color("dark")};
    padding: 22px 52px;
    display: inline-block;
    transition: color 0.2s ease, border-color 0.2s ease, opacity 0.2s, visibility 0.2s;
    text-transform: uppercase;
    cursor: pointer;
    background: none;
    opacity: ${props => props.loadingMore ? "0" : "1"};
    visibility: ${props => props.loadingMore ? "hidden" : "visible"};

    &:hover{
        color: ${color("dark blue")};
        border-color: ${color("dark blue")};
    }

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }

    @media screen and (min-width: 1200px) {
        padding: 24px 52px;
    }
`;

const Loading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s, visibility 0.2s;
    opacity: ${props => props.loadingMore ? "1" : "0"};
    visibility: ${props => props.loadingMore ? "visible" : "hidden"};
`;

const Spinner = styled.div`
    border-radius: 50%;
    animation: 1.2s linear 0s infinite running spin;

    svg{
        fill: ${color("blue")};
        stroke: ${color("blue")};
        width: 60px;
        height: 60px;
    }

    @keyframes spin{
        0%{
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media screen and (min-width: 1200px) {
        svg{
            width: 100px;
            height: 100px;
        }
    }
`;

const InfiniteScrollButton = ({loadingMore, onClick}) => {

    return (
        <Wrapper>
            <Loading loadingMore={loadingMore}>
                <Spinner>
                    <SpinnerSVG />
                </Spinner>
            </Loading>
            <Button loadingMore={loadingMore} onClick={onClick}>
                Load More
            </Button>
        </Wrapper>
    );
};

export default InfiniteScrollButton;
