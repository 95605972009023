import React from "react";
import styled from "@emotion/styled";
import Container from "../Context/Container";
import Section from "../Context/Section";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import CustomLink from "../Elements/CustomLink";
import GatsbyImage from "gatsby-image";
import Insight from "./Insight";

const ArticleImageWrapper = styled.span`
    overflow: hidden;
    display: block;
    padding-top: 68.7%;
    position: relative;

    @media screen and (min-width: 768px) {
        padding-top: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
const ArticleImage = styled(GatsbyImage)`
    transition: transform 0.4s;
    will-change: transform;
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;
const GradientOverlay = styled.span`
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(6, 7, 27, 0) 17.53%, rgba(6, 7, 27, 0.79) 52.26%);
        mix-blend-mode: multiply;
    }
`;
const ArticleMeta = styled.span`
    display: flex;
    align-items: center;
    background-color: ${color("green")};
    padding: 9px 28px;
    border-bottom: 28px solid ${color("white")};

    @media screen and (min-width: 768px) {
        padding: 0;
        border-bottom: none;
        background: none;
        justify-content: flex-end;
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 40px;
    }
`;
const PublishDate = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;

    @media screen and (min-width: 1200px) {
        margin-bottom: 0;
    }
`;
const Author = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
`;
// const ArticleCategories = styled.span`
//     display: block;
//     padding: 0 18px;
//     background-color: ${color("white")};

//     @media screen and (min-width: 768px) {
//         display: none;
//     }
// `;
// const Category = styled(CustomLink)`
//     ${typography.meta};
//     text-transform: uppercase;
//     color: ${color("meta grey")};
//     line-height: 1;
//     display: inline-block;
//     border-right: 1px solid ${color("meta grey")};
//     padding: 0 10px;
//     text-decoration: none;
//     transition: color 0.2s;

//     @media screen and (min-width: 768px) {
//         padding: 0;
//     }

//     &:last-child {
//         border-right: none;
//     }

//     &:hover {
//         text-decoration: underline;
//         color: ${color("dark blue")};
//     }
// `;
const ArticleTitle = styled.h3`
    ${typography.p1};
    font-weight: bold;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        ${typography.h3};
        
        @media screen and (min-width: 768px) {
            margin-bottom: 30px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 40px;
        }
    }
`;
const ArticleDescription = styled.span`
    display: block;
    ${typography.standard};
    margin-bottom: 16px;
    flex-grow: 1;

    @media screen and (min-width: 768px) {
        display: none;
    }
`;
const ArticleCta = styled.span`
    ${typography.link};
    display: inline-block;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
        color: ${color("blue")};

        @media screen and (min-width: 768px) {
            color: ${color("green")};
        }
    }
`;
const ArticleLink = styled(CustomLink)`
    display: block;
    text-decoration: none;
    color: ${color("dark")};
    
    @media screen and (min-width: 768px) {
        color: ${color("white")};
    }
`;
// const ArticleDetails = styled(CustomLink)`
//     display: inline-block;
//     text-decoration: none;
//     color: ${color("dark")};
//     padding: 16px 28px 28px;
//     background-color: ${color("white")};
//     flex-grow: 1;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     hr{
//         border-color: ${hexToRGB(color("grey"),0.3)};
//     }

//     @media screen and (min-width: 768px) {
//         padding: 0;
//         color: ${color("white")};
//         background: none;
//     }
// `;
// const ArticleTop = styled(CustomLink)`
//     display: block;
//     text-decoration: none;
//     color: ${color("dark")};
//     position: relative;
// `;
const ArticleBottom = styled.span`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    z-index: 50;

    @media screen and (min-width: 768px) {
        text-align: right;
        color: ${color("white")};
        max-width: 576px;
    }
`;
const ArticleDetailsWrapper = styled.span`
    display: block;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const ArticleDesktopSizer = styled.span`
    @media screen and (min-width: 768px) {
        display: block;
        position: relative;
        padding-top: 30%;
        min-height: 30vh;
        border-left: 3px solid ${color("border")};
        border-right: 3px solid ${color("border")};

        &:hover {
            ${ArticleImage} {
                transform: scale(1.02);
            }
        }
    }

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }
`;
const ArticleDesktopSizerInner = styled.span`
    @media screen and (min-width: 768px) {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
    }
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 15px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    margin: 0 10px;

    @media screen and (min-width: 768px) {
        width: 34px;
        margin: 0 12px;
    }
`;

const Mobile = styled.div`
    display: block;
    
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const Desktop = styled.div`
    display: none;
    
    @media screen and (min-width: 768px) {
        display: block;
        position: relative;
        z-index: 100;
    }
`;

const FeatureInsight = ({article, linkText, fallbackImage, border, bg, theme}) => {


    const {url, imageFeature, alt, date, author, description, title} = article;

    const insightMobile = (
        <Section bg={bg ? bg : "white"} fg="dark" hr={border ? "grey" : null} paddingS="0" theme={theme}>
            <Container type="full-no-padding">
                <Insight
                    {...article}
                    linkText={linkText}
                    fallbackImage={fallbackImage}
                />
            </Container>
        </Section>
    );

    const featureImage = imageFeature ?? fallbackImage;

    const insightDesktop = (
        <ArticleLink url={url} internal={true}>
            <ArticleDesktopSizer>
                <ArticleDesktopSizerInner>
                        <Container inline={true}>
                            {featureImage ? (
                                <>
                                    <ArticleImageWrapper>
                                        <ArticleImage fluid={{...featureImage, sizes: "100vw"}} alt={alt} loading="eager" />
                                    </ArticleImageWrapper>
                                    <GradientOverlay />
                                </>
                            ) : null}
                            <ArticleDetailsWrapper>
                                <ArticleBottom>
                                    <ArticleMeta>
                                        <PublishDate>{date}</PublishDate>
                                        <Line />
                                        <Author>{author}</Author>
                                    </ArticleMeta>
                                    <ArticleTitle>
                                        {title}
                                    </ArticleTitle>
                                    <ArticleDescription dangerouslySetInnerHTML={{ __html: description }} />
                                    <ArticleCta>
                                        {linkText}
                                    </ArticleCta>
                                </ArticleBottom>
                            </ArticleDetailsWrapper>
                        </Container>
                </ArticleDesktopSizerInner>
            </ArticleDesktopSizer>
        </ArticleLink>
    );

    return (
        <>
            <Mobile>{insightMobile}</Mobile>
            <Desktop>{insightDesktop}</Desktop>
        </>
    );
};

export default FeatureInsight;
