import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Footer from "../components/Context/Footer";
import HeroInsightListing from "../components/General/HeroInsightListing";
import LandingInsightsBlock from "../components/Insights/LandingInsightsBlock";
import CTA from "../components/General/CTA";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";
import { useInsightsStoreContext } from "../components/Insights/InsightsStore";
import FeatureInsight from "../components/Insights/FeatureInsight";
import CategoriesList from "../components/Insights/CategoriesList";

const InsightListing = ({ data: staticData, pageContext }) => {
    const data = usePreviewData(staticData);
    const {title, meta_description, share_image, introduction, heading_promo, teaser, button_cta, link_promo, featured_insight} = data.prismicInsightsLanding.data;
    const category = pageContext.type === 'category' ? data.prismicCategory.data : null;
    const articlePlaceholderImage = data.file.childImageSharp.fluid;
    const store = useInsightsStoreContext();
    const key = pageContext.type === 'category' ? `insights/category-${pageContext.slug}` : 'insights';
    useEffect(() => {
        if(store.isInitializing(key)) {
            store.updateState({
                [`${key}/${pageContext.currentPage}`]: pageContext.data,
                [`${key}/cursor`]: pageContext.currentPage + 1
            })
        }
    }, [pageContext, key])
    let featuredInsight;
    if(category) {
        if(category.featured_insight?.document) {
            featuredInsight = {
                title: category.featured_insight.document?.data.title.text,
                categories: category.featured_insight.document?.data.categories,
                description: category.featured_insight.document?.data.summary?.html,
                imageFeature: category.featured_insight?.document?.data.large_thumbnail.fluid,
                image: category.featured_insight?.document?.data.thumbnail.fluid,
                author: category.featured_insight.document?.data.author.document?.data.name.text,
                date: category.featured_insight?.document?.data.date,
                url: category.featured_insight?.document?.url
            };
        }
    } else if(featured_insight?.document) {
        featuredInsight = {
            title: featured_insight.document?.data.title.text,
            categories: featured_insight.document?.data.categories,
            description: featured_insight.document?.data.summary?.html,
            imageFeature: featured_insight.document?.data.large_thumbnail?.fluid,
            image: featured_insight?.document?.data.thumbnail.fluid,
            author: featured_insight.document?.data.author.document?.data.name.text,
            date: featured_insight.document?.data.date,
            url: featured_insight.document?.url
        };
    }

    const theme = "dark";

    return (
        <Layout>
            <SEO title={title.text} description={category?.meta_description ?? meta_description} image={category?.share_image?.url ?? share_image.url} />
            <Header theme={theme} />
            <HeroInsightListing
                title={title.text}
                subtitle={introduction.html}
                theme={theme}
            />
            {featuredInsight ? <FeatureInsight
                article={featuredInsight}
                fallbackImage={articlePlaceholderImage}
                linkText="Read more"
                theme={theme}
            /> : null}
            <CategoriesList
                categories={pageContext.categories}
                theme={theme}
                active={pageContext.type === "category" ? pageContext.slug : false}
            />
            <LandingInsightsBlock
                theme={theme}
                bg="insights blue"
                store={store}
                typeKey={key}
                currentPage={pageContext.currentPage}
                totalPages={pageContext.numPages}
                fallbackImage={articlePlaceholderImage}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query insightArticlesQuery($slug: String) {
        prismicInsightsLanding {
            data {
                title {
                    text
                }
                introduction {
                    html
                }
                featured_insight {
                    document {
                        ...on PrismicInsight {
                            url
                            data {
                                title {
                                    text
                                }
                                date(formatString: "MMM D YYYY")
                                author {
                                    document {
                                        ... on PrismicAuthor {
                                            data {
                                                name {
                                                    text
                                                }
                                            }
                                        }
                                    }
                                }
                                categories {
                                    category {
                                        document {
                                            ... on PrismicCategory {
                                                data {
                                                    title
                                                }
                                                uid
                                            }
                                        }
                                    }
                                }
                                summary {
                                    html
                                }
                                large_thumbnail {
                                    alt
                                    fluid(maxWidth: 5000) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                                thumbnail {
                                    alt
                                    fluid(maxWidth: 2250) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                            }    
                        }
                        
                    }
                }
                heading_promo {
                    text
                }
                teaser {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
            }
        }
        prismicCategory(uid: {eq: $slug}) {
            data {
                title
                meta_description
                share_image {
                    url
                }
                featured_insight {
                    document {
                        ...on PrismicInsight {
                            url
                            data {
                                title {
                                    text
                                }
                                date(formatString: "MMM D YYYY")
                                author {
                                    document {
                                        ... on PrismicAuthor {
                                            data {
                                                name {
                                                    text
                                                }
                                            }
                                        }
                                    }
                                }
                                categories {
                                    category {
                                        document {
                                            ... on PrismicCategory {
                                                data {
                                                    title
                                                }
                                                uid
                                            }
                                        }
                                    }
                                }
                                summary {
                                    html
                                }
                                large_thumbnail {
                                    alt
                                    fluid(maxWidth: 5000) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                                thumbnail {
                                    alt
                                    fluid(maxWidth: 2250) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
        file(name: {eq: "placeholder-insights-image"}) {
            childImageSharp {
                fluid(maxWidth: 5000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;

export default InsightListing;
