import React, {useRef, useEffect, useState, useCallback} from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import CustomLink from "../Elements/CustomLink";
import Container from "../Context/Container";
import ChevronLeftThinSvg from "../SVGs/ChevronLeftThinSvg";
import { af as AF } from '@gladeye/af'
import { easing } from "../../util/math";

const Wrapper = styled.div`
    border-top: 1px solid ${color("grey")};
    position: relative;

    @media screen and (min-width: 768px) {
        border-top: none;
    }
`;

const ScrollContainer = styled.div`
    overflow-x: auto;
    cursor: ${props => props.active ? "grabbing" : "grab"};

    -ms-overflow-style: none; // hide scrollbar
    scrollbar-width: none; // hide scrollbar

    &::-webkit-scrollbar {
        display: none; // hide scrollbar
    }
`;

const CategoryWrapper = styled.div`
    display: inline-block;
    margin-left: ${props => `${props.marginWidth}px`};
    margin-right: ${props => `${props.marginWidth}px`};
    padding: 0 20px;

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }
`;

const CategoryList = styled.ul`
    display: flex;
`;

const CategoryItem = styled.li`
    display: inline-block;
    margin-right: 26px;
    white-space: nowrap;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (min-width: 768px) {
        margin-right: 78px;
    }
`;

const CategoryLink = styled(CustomLink)`
    ${typography.label};
    display: inline-block;
    text-decoration: none;
    transition: color 0.2s;
    border-bottom: ${props => props.active ? `10px solid ${color("dark")}` : "none"};
    padding-top: 25px;
    padding-bottom: ${props => props.active ? "15px" : "25px"};
    font-weight: ${props => props.active ? "bold" : "normal"};
    color: ${props => props.active ? color("dark") : color("blue grey")};

    @media screen and (min-width: 768px) {
        padding-top: 38px;
        padding-bottom: ${props => props.active ? "28px" : "38px"};
    }

    @media screen and (min-width: 1800px) {
        padding-top: 48px;
        padding-bottom: ${props => props.active ? "38px" : "48px"};
    }

    &:hover {
        color: ${color("dark")};
    }

    &::selection {
        background: transparent;
    }
`;

const Overlap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    visibility: ${props => props.isHidden ? "hidden" : "visible"};
`;

const CustomContainer = styled(Container)`
    position: relative;
    height: 100%;

    @media screen and (min-width: 600px) {
        position: static;
    }
`;

const Relative = styled.div`
    height: 100%;

    @media screen and (min-width: 600px) {
        position: relative;
    }
`;

const Controller = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 600px) {
        right: auto;
        left: 100%;
        min-width: 48px;
    }

    @media screen and (min-width: 768px) {
        right: auto;
        width: calc((100vw - 1000px) * 0.5 + 48px);
        min-width: 48px;
    }

    @media screen and (min-width: 1200px) {
        width: calc((100vw - 1120px) * 0.5 + 48px);
    }

    @media screen and (min-width: 1800px) {
        width: calc((100vw - 1430px) * 0.5 + 48px);
    }
`;

const ArrowButton = styled.button`
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    height: 100%;
    z-index: 10;
    position: relative;
    pointer-events: all;

    &:hover {
        svg {
            transform: scaleX(-1) translateX(-5px);
        }
    }

    &:focus {
        outline: none;
    }

    svg {
        stroke: ${color("blue grey")};
        transition: transform 0.2s;
        transform: scaleX(-1);
    }
`;

const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${color("white")};
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
`;

const Solid = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    pointer-events: all;
    background: ${color("white")};
`;

const CategoriesList = ({categories, border, bg, theme, active}) => {
    const slider = useRef();
    const relativeContainer = useRef();
    const af = useRef(null);

    const speed = 1;
    const autoScrollAmount = 150;
    const autoScrollTime = 300;

    const [isActive, setIsActive] = useState(false);
    const [marginWidth, setMarginWidth] = useState(0);
    const [hideArrow, setHideArrow] = useState(true);

    const dragMovement = useRef(0);
    const isDown = useRef(false);
    const startX = useRef();
    const scrollLeft = useRef();
    const startScrollAuto = useRef(0);
    const timeStartScrollAuto = useRef(0);

    const handleMouseDown = useCallback((e) => {
        isDown.current = true;
        setIsActive(true);
        startX.current = e.pageX - slider.current.offsetLeft;
        scrollLeft.current = slider.current.scrollLeft;
        dragMovement.current = 0;
    },[isDown, startX, scrollLeft, slider])

    const handleMouseUp = useCallback((e) => {
        isDown.current = false;
        setIsActive(false);
    }, [isDown])

    const handleMouseMove = useCallback((e) => {
        if (!isDown.current) return;
        e.preventDefault();
        const x = e.pageX - slider.current.offsetLeft;
        const walk = (x - startX.current) * speed;
        slider.current.scrollLeft = scrollLeft.current - walk;
        dragMovement.current++;
    },[isDown, startX, scrollLeft, slider])

    const handleClick = (e) => {
        if(dragMovement.current > 7) e.preventDefault();
    }

    const handleDragStart = (e) => {
        e.preventDefault();
    }

    const handleArrowClick = () => {
        timeStartScrollAuto.current = window.performance.now();
        scrollLeft.current = startScrollAuto.current = slider.current.scrollLeft;
        af.current.addWrite(animateScroll)
    }

    const animateScroll = () => {
        const prevScrollLeft = scrollLeft.current;

        const time = window.performance.now();
        const timePassed = time - timeStartScrollAuto.current;
        const fractionTimePassed = timePassed / autoScrollTime;
        
        const easedFractionShouldBeScrolled = easing.inOutQuad(fractionTimePassed);
        const scrollToThisFrame = startScrollAuto.current + autoScrollAmount * easedFractionShouldBeScrolled;

        slider.current.scrollLeft = scrollToThisFrame;
        scrollLeft.current = slider.current.scrollLeft;

        if(fractionTimePassed >= 1 || prevScrollLeft > scrollLeft.current) {
            af.current.removeWrite(animateScroll)
        }
    }

    const calcMaxWidth = (ww) => {
        if(ww >= 1800) {
            return 1430;
        }else if(ww >= 1200) {
            return 1120;
        }else if(ww >= 1000) {
            return 1000;
        }else {
            return ww;
        }
    }

    const updateMaxWidth = useCallback(() => {
        const ww = document.body.clientWidth; // window width minus scrollbar width
        const maxWidth = calcMaxWidth(ww);
        const newMarginWidth = (ww - maxWidth) * 0.5;
        setMarginWidth(newMarginWidth);
    },[]);

    const checkIsScrollable = useCallback(() => {
        console.log(slider.current, relativeContainer.current);
        const containerWidth = relativeContainer.current?.clientWidth;
        const listWidth = slider.current?.children[0]?.children[0]?.clientWidth;
        const isScrollable = listWidth > containerWidth;

        setHideArrow(!isScrollable);
    }, []);

    const handleResize = useCallback(() => {
        updateMaxWidth();
        checkIsScrollable();
    },[updateMaxWidth]);

    useEffect(() => {
        const sliderEl = slider.current;
        af.current = AF()

        if(sliderEl) {
            updateMaxWidth();
            checkIsScrollable();

            sliderEl.addEventListener("dragstart", handleDragStart);
            sliderEl.addEventListener("mousedown", handleMouseDown);
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("resize", handleResize);

            return () => {
                sliderEl.removeEventListener("dragstart", handleDragStart);
                sliderEl.removeEventListener("mousedown", handleMouseDown);
                window.removeEventListener("mouseup", handleMouseUp);
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("resize", handleResize);
                af.current.removeWrite(animateScroll)
            }
        }
    }, [handleMouseDown, handleMouseUp, handleMouseMove, handleResize, updateMaxWidth])
    
    return categories && categories.length ? (
        <Section
            bg={bg ? bg : "white"}
            fg="dark"
            hr={border ? "grey" : null}
            paddingS="0"
            paddingM="0"
            paddingL="0"
            paddingXL="0"
            paddingXXL="0"
            noFlex={true}
            theme={theme}
        >
            <Wrapper>
                <ScrollContainer
                    active={isActive}
                    ref={slider}
                >
                    <CategoryWrapper
                        marginWidth={marginWidth}
                    >
                        <CategoryList>
                            <CategoryItem>
                                <CategoryLink url="/insights" internal={true} onClick={handleClick} active={!active} draggable="false">Latest</CategoryLink>
                            </CategoryItem>
                            {categories.map(category => {
                                return <CategoryItem key={category.uid}>
                                    <CategoryLink url={`/insights/category-${category.uid}`} internal={true} onClick={handleClick} active={category.uid === active} draggable="false">
                                        {category.title}
                                    </CategoryLink>
                                </CategoryItem>
                            })}
                        </CategoryList>
                    </CategoryWrapper>
                </ScrollContainer>
                <Overlap isHidden={hideArrow}>
                    <CustomContainer>
                        <Relative ref={relativeContainer}>
                            <Controller>
                                <Gradient></Gradient>
                                <Solid></Solid>
                                <ArrowButton onClick={handleArrowClick}>
                                    <ChevronLeftThinSvg />
                                </ArrowButton>
                            </Controller>
                        </Relative>
                    </CustomContainer>
                </Overlap>
            </Wrapper>
        </Section>
    ) : null;
};

export default CategoriesList;
