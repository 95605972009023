import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import { Link } from 'gatsby';
import Insight from "./Insight";
import Container from "../Context/Container";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import InfiniteScroller from "./InfiniteScroller";

const Intro = styled.div`
    margin-bottom: ${props => props.large ? "40px" : "0"};

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: ${props => props.large ? "56px" : "0"};
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: ${props => props.large ? "57px" : "0"};
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: ${props => props.large ? "49px" : "0"};
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    a{
        ${typography.link};
        color: ${color("dark")};
        display: flex;
        flex-direction: row;
        transition: color 0.2s ease;
        white-space: nowrap;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 26px;
    }

    @media screen and (min-width: 1200px) {
        align-items: flex-end;
        margin-bottom: 34px;

        a{
            margin-bottom: 0.6em;
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 28px;
    }
`;
const Title = styled.h2`
    ${props => props.large ? typography.h2 : typography.h3};
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;
    font-weight: 600;

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 0;
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    p{
        @media screen and (min-width: 1200px) {
            max-width: 986px;
        }

        @media screen and (min-width: 1800px) {
            max-width: 1100px;
        }
    }
`;
const FeaturedWrapper = styled.div`
    margin-bottom: -21px;
   @media screen and (min-width: 768px) {
        display: grid;
        gap: 31px;
        grid-template-columns: 1fr;
        margin-left: -7.5px;
        margin-right: -7.5px;
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
        margin-left: -11.5px;
        margin-right: -11.5px;
    }

    @media screen and (min-width: 1800px) {
        margin-left: -14px;
        margin-right: -14px;
    }
`;

const LandingInsightsBlock = ({title, intro, viewAll, border, bg, theme, sectionSibling, typeKey, store, totalPages, currentPage, fallbackImage}) => {
    let ArticleList = [];
    let numberOfItemsOnLatestPage = 0;
    let count = 0;
    for(let pageNum = currentPage ;; pageNum++) {
        let pageKey = `${typeKey}/${pageNum}`
        if(store[pageKey]) {
            store[pageKey].forEach((item, i) => {
                ArticleList.push(<Insight
                    title={item.data.title.text}
                    description={item.data.summary?.html}
                    date={item.data.date}
                    author={item.data.author.document?.data.name.text}
                    image={item.data.thumbnail?.fluid ?? fallbackImage}
                    alt={item.data.thumbnail?.alt}
                    categories={item.data.categories}
                    linkText={"Read More"}
                    url={item.url}
                    internal={true}
                    useLayout={count % 5 === 2 ? "double" : "single"}
                    key={item.id}
                    index={count}
                />);
                count++;
            })
            numberOfItemsOnLatestPage = store[pageKey].length;

        } else {
            // if(store[`${typeKey}/shouldLoadMore`] && store.hasMore(totalPages, typeKey)) {
            //     for(let j = 0; j < numberOfItemsOnLatestPage; j++) {
            //         items.push(<Tile key={`tile-${j}`} />);
            //     }
            // }
            break;
        }
    }

    return (
        <Section bg={bg ? bg : "white"} fg="dark" hr={border ? "grey" : null} theme={theme} sectionSibling={sectionSibling}>
            <Container>
                {title || viewAll ? (
                    <Intro large={viewAll}>
                        <Row>
                            <Title large={viewAll}>
                                {title}
                            </Title>
                            {viewAll ? <Link to="/insights">See the latest</Link> : null}
                        </Row>
                        <Standard dangerouslySetInnerHTML={{ __html: intro }} />
                    </Intro>
                ) : null}
                <InfiniteScroller
                    onLoadMore={() => {
                        console.log('loadMore');
                        store.loadMore(typeKey);
                    }}
                    hasMore={store.hasMore(totalPages, typeKey)}
                    loading={store[`${typeKey}/loading`]}
                    shouldInfiniteLoad={store[`${typeKey}/shouldInfiniteLoad`]}>
                    <FeaturedWrapper>
                        {ArticleList}
                    </FeaturedWrapper>
                </InfiniteScroller>
            </Container>
        </Section>
    );
};

export default LandingInsightsBlock;
