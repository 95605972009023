import React, { createRef } from "react";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import InfiniteScrollButton from "./InfiniteScrollButton";

const Container = styled.div``;
const Sentinel = styled.div``;
// const LoadMore = styled.button``;

export default class InfiniteScroller extends React.Component {
    sentinelRef = createRef()
    componentDidMount() {
        this.setupSentinel()
    }
    componentWillUnmount() {
        this.destroySentinel()
    }

    setupSentinel() {
        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && this.props.hasMore && this.props.shouldInfiniteLoad && this.props.onLoadMore) {
                    this.props.onLoadMore();
                }
            })
        }, {rootMargin: '0px 0px 350px 0px'})
        this.observer.observe(this.sentinelRef.current);
    }
    destroySentinel() {
        this.observer.disconnect();
    }
    render() {
        let { children, hasMore, shouldInfiniteLoad, onLoadMore, loading = false } = this.props;

        return <Container>
            {children}
            <Sentinel ref={this.sentinelRef}>
                {hasMore && !shouldInfiniteLoad || loading ? <InfiniteScrollButton loadingMore={loading} onClick={onLoadMore} /> : null}
            </Sentinel>
        </Container>;
    }
}

InfiniteScroller.propTypes = {
    children: PropTypes.any,
    onLoadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    shouldInfiniteLoad: PropTypes.bool
}